import React, { ReactElement } from "react";

import ChooseIcon from "../icons/chooseIcon";
import CompareIcon from "../icons/compareIcon";
import MoneyBackIcon from "../icons/moneyBackIcon";
import PeaceOfMindIcon from "../icons/peaceOfMindIcon";
import styles from "./whyUs.module.scss";
import FeatureList from "@components/shared/featureList";
import colors from "@styles/variables/perfectautowarrantyVariables.module.scss";
import SupportIcon from "../icons/SupportIcon";
import ComfortIcon from "../icons/ComfortIcon";

const whyUsList = [
    {
        title: "Compare",
        description: "Receive tailored estimates from several vendors",
        icon: <ChooseIcon />,
    },
    {
        title: "Economical",
        description: "Budget-friendly programs with low upfront fees",
        icon: <MoneyBackIcon />,
    },
    {
        title: "Choose",
        description: "Choose a suitable plan",
        icon: <CompareIcon />,
    },
    {
        title: "Risk-Free ",
        description: "All our offerings have a 30-day money-back guarantee",
        icon: <PeaceOfMindIcon />,
    },
    {
        title: "Support",
        description: "Our plans include 24/7 customer support",
        icon: <SupportIcon />,
    },
    {
        title: "Comfort",
        description: "Drive worry-free thanks to your extended car warranty",
        icon: <ComfortIcon />,
    },
];

export default function WhyUs(): ReactElement {
    return (
        <FeatureList
            featureList={whyUsList}
            mainTitle="Benefits of an Extended Warranty for Your Car."
            mainDescription="Great protection with exclusive offers you won't find elsewhere."
            classes={{
                mainSectionClasses:
                    "max-w-7xl mx-auto px-5 mt-10 mb-16 lg:mt-20 ",
                mainTitleClasses: `text-2xl font-light lg:text-4xl mb-2 lg:mb-3 mx-auto ${styles["width"]}`,
                mainDescriptionClasses: `text-base font-light mb-0 mx-auto  ${styles["width"]}`,
                innerSectionClasses:
                    "  w-full md:w-7/12 lg:w-full mt-10 md:mx-auto  lg:flex lg:flex-wrap lg:justify-between lg:mt-14 ",
                oneBoxClasses: `  flex flex-col items-center justify-center text-center mb-10 lg:mb-9 lg:w-1/3 ${styles["list-item"]}`,
                iconClasses: "mb-6",
                titleClasses:
                    "text-base lg:text-lg font-normal mb-1 lg:mb-2 leading-4 lg:leading-4",
                descriptionClasses: "text-sm font-light",
            }}
            colors={{
                mainTitleColor: colors.blackLead,
                mainDescriptionColor: "#919191",
                descriptionColor: "#919191",
            }}
        />
    );
}
