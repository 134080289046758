import React from "react";

function CompareIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="189"
            height="89"
            fill="none"
            viewBox="0 0 189 89"
        >
            <g clipPath="url(#clip0_1_36)">
                <g fill="#D8D8D8" opacity="0.5">
                    <path
                        d="M184.881 64h-2.062a4.1 4.1 0 00-2.842 1.26 4.193 4.193 0 00-1.168 2.907c0 1.086.419 2.129 1.168 2.907a4.1 4.1 0 002.842 1.26h2.062a4.1 4.1 0 002.843-1.26 4.193 4.193 0 001.168-2.907c0-1.086-.419-2.13-1.168-2.908A4.1 4.1 0 00184.881 64zM91.178 80.667h-2.06a4.1 4.1 0 00-2.842 1.259 4.193 4.193 0 00-1.168 2.907c0 1.086.42 2.13 1.168 2.908A4.1 4.1 0 0089.12 89h2.059a4.08 4.08 0 001.61-.284c.514-.203.981-.507 1.376-.896a4.17 4.17 0 00.922-1.366 4.21 4.21 0 00-.922-4.607 4.115 4.115 0 00-1.375-.896 4.08 4.08 0 00-1.611-.284zM180.928 80.667h-16.645a4.083 4.083 0 01-2.986-1.18 4.212 4.212 0 01-.922-4.608c.214-.513.528-.977.922-1.366a4.083 4.083 0 012.986-1.18h6.594a4.1 4.1 0 002.843-1.259 4.193 4.193 0 001.168-2.907c0-1.086-.419-2.13-1.168-2.908A4.1 4.1 0 00170.877 64h-51.34a4.11 4.11 0 00-2.986 1.18 4.212 4.212 0 00-.922 4.608c.214.513.527.977.922 1.365a4.11 4.11 0 002.986 1.18h8.746a4.1 4.1 0 012.843 1.26 4.193 4.193 0 011.168 2.907c0 1.086-.419 2.13-1.168 2.907a4.101 4.101 0 01-2.843 1.26H103.12a4.098 4.098 0 00-2.842 1.259 4.191 4.191 0 00-1.169 2.907c0 1.086.42 2.13 1.169 2.908A4.099 4.099 0 00103.12 89h77.802a4.1 4.1 0 002.842-1.26 4.193 4.193 0 001.168-2.907c0-1.086-.419-2.129-1.168-2.907a4.1 4.1 0 00-2.842-1.26h.006z"
                        opacity="0.5"
                    ></path>
                </g>
                <path
                    stroke="#000"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M184.771 73.159a5.154 5.154 0 01-.857 2.848 5.096 5.096 0 01-2.282 1.888 5.05 5.05 0 01-5.543-1.111 5.158 5.158 0 01-1.102-5.587 5.12 5.12 0 011.873-2.3 5.057 5.057 0 016.422.637 5.15 5.15 0 011.489 3.625v0z"
                ></path>
            </g>
            <g fill="#D8D8D8" clipPath="url(#clip1_1_36)" opacity="0.5">
                <path
                    d="M4.237 25H6.36a4.275 4.275 0 002.924-1.26 4.132 4.132 0 001.202-2.907c0-1.086-.431-2.129-1.202-2.907a4.275 4.275 0 00-2.924-1.26H4.237a4.275 4.275 0 00-2.924 1.26 4.131 4.131 0 00-1.202 2.907c0 1.086.432 2.13 1.202 2.908S3.133 24.97 4.237 25zM100.644 8.333h2.119a4.276 4.276 0 002.924-1.259 4.13 4.13 0 001.202-2.907 4.13 4.13 0 00-1.202-2.908A4.276 4.276 0 00102.763 0h-2.119a4.306 4.306 0 00-1.657.284 4.25 4.25 0 00-1.415.896 4.166 4.166 0 00-.949 1.366 4.107 4.107 0 000 3.242c.22.513.543.977.95 1.365a4.25 4.25 0 001.414.896c.527.203 1.091.3 1.657.284zM8.305 8.333H25.43a4.306 4.306 0 011.658.285 4.25 4.25 0 011.414.895c.406.389.729.853.95 1.366a4.107 4.107 0 010 3.242c-.221.513-.544.977-.95 1.366a4.248 4.248 0 01-1.414.895c-.528.203-1.091.3-1.658.285h-6.784a4.275 4.275 0 00-2.925 1.259 4.131 4.131 0 00-1.201 2.907c0 1.086.43 2.13 1.201 2.908.771.778 1.82 1.23 2.925 1.259h52.821a4.308 4.308 0 001.658-.284 4.25 4.25 0 001.414-.896c.406-.388.729-.853.95-1.366a4.107 4.107 0 000-3.242 4.167 4.167 0 00-.95-1.366 4.25 4.25 0 00-1.414-.895 4.308 4.308 0 00-1.658-.284h-8.999a4.275 4.275 0 01-2.924-1.26 4.131 4.131 0 01-1.202-2.907c0-1.086.431-2.13 1.202-2.907a4.275 4.275 0 012.924-1.26h25.89a4.275 4.275 0 002.924-1.259 4.131 4.131 0 001.202-2.907c0-1.086-.431-2.13-1.202-2.908A4.275 4.275 0 0088.358 0H8.31a4.275 4.275 0 00-2.924 1.26 4.131 4.131 0 00-1.202 2.907c0 1.086.432 2.129 1.202 2.907s1.82 1.23 2.924 1.26h-.006z"
                    opacity="0.5"
                ></path>
            </g>
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M69 10h-1.65M51.14 24H32.342m-9.193 0h1.468m35.25-14H14"
            ></path>
            <rect
                width="39"
                height="40"
                x="84"
                y="17"
                fill="#C5EFF9"
                rx="4"
            ></rect>
            <rect
                width="39"
                height="39"
                x="65"
                y="33"
                fill="#C5EFF9"
                rx="4"
            ></rect>
            <rect
                width="38"
                height="37"
                x="81"
                y="14"
                stroke="#131E29"
                strokeWidth="2"
                rx="3"
            ></rect>
            <rect
                width="38"
                height="38"
                x="69"
                y="29"
                stroke="#131E29"
                strokeWidth="2"
                rx="3"
            ></rect>
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M44 40h8M48 44v-8"
            ></path>
            <defs>
                <clipPath id="clip0_1_36">
                    <path
                        fill="#fff"
                        d="M0 0H104V25H0z"
                        transform="translate(85 64)"
                    ></path>
                </clipPath>
                <clipPath id="clip1_1_36">
                    <path
                        fill="#fff"
                        d="M0 0H107V25H0z"
                        transform="rotate(-180 53.5 12.5)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default CompareIcon;
