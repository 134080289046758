import React from "react";

function PeaceOfMindIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="181"
            height="88"
            fill="none"
            viewBox="0 0 181 88"
        >
            <g fill="#D8D8D8" clipPath="url(#clip0_1_110)" opacity="0.5">
                <path
                    d="M176.881 63h-2.062a4.1 4.1 0 00-2.842 1.26 4.193 4.193 0 00-1.168 2.907c0 1.086.419 2.129 1.168 2.907a4.1 4.1 0 002.842 1.26h2.062a4.1 4.1 0 002.843-1.26 4.193 4.193 0 001.168-2.907c0-1.086-.419-2.13-1.168-2.908A4.1 4.1 0 00176.881 63zM83.178 79.667h-2.06a4.1 4.1 0 00-2.842 1.259 4.193 4.193 0 00-1.168 2.907c0 1.086.42 2.13 1.168 2.908A4.1 4.1 0 0081.12 88h2.059a4.08 4.08 0 001.61-.284c.514-.203.981-.507 1.376-.896a4.17 4.17 0 00.922-1.366 4.21 4.21 0 00-.922-4.607 4.115 4.115 0 00-1.375-.896 4.08 4.08 0 00-1.611-.284zM172.928 79.667h-16.645a4.083 4.083 0 01-2.986-1.18 4.212 4.212 0 01-.922-4.608c.214-.513.528-.977.922-1.366a4.083 4.083 0 012.986-1.18h6.594a4.1 4.1 0 002.843-1.259 4.193 4.193 0 001.168-2.907c0-1.086-.419-2.13-1.168-2.908A4.1 4.1 0 00162.877 63h-51.34a4.11 4.11 0 00-2.986 1.18 4.212 4.212 0 00-.922 4.608c.214.513.527.977.922 1.365a4.11 4.11 0 002.986 1.18h8.746a4.1 4.1 0 012.843 1.26 4.193 4.193 0 011.168 2.907c0 1.086-.419 2.13-1.168 2.907a4.101 4.101 0 01-2.843 1.26H95.12a4.1 4.1 0 00-2.843 1.259 4.193 4.193 0 00-1.168 2.907c0 1.086.42 2.13 1.169 2.908A4.1 4.1 0 0095.12 88h77.802a4.1 4.1 0 002.842-1.26 4.193 4.193 0 001.168-2.907c0-1.086-.419-2.129-1.168-2.907a4.1 4.1 0 00-2.842-1.26h.006z"
                    opacity="0.5"
                ></path>
            </g>
            <g fill="#D8D8DD" clipPath="url(#clip1_1_110)" opacity="0.5">
                <path
                    d="M4.237 27H6.36a4.275 4.275 0 002.924-1.26 4.131 4.131 0 001.202-2.907c0-1.086-.431-2.129-1.202-2.907a4.275 4.275 0 00-2.924-1.26H4.237a4.275 4.275 0 00-2.924 1.26 4.132 4.132 0 00-1.202 2.907c0 1.086.432 2.13 1.202 2.908s1.82 1.23 2.924 1.26zM100.644 10.333h2.119a4.276 4.276 0 002.924-1.259 4.13 4.13 0 001.202-2.907 4.13 4.13 0 00-1.202-2.908A4.276 4.276 0 00102.763 2h-2.119a4.306 4.306 0 00-1.657.284 4.25 4.25 0 00-1.415.896 4.166 4.166 0 00-.949 1.366 4.107 4.107 0 000 3.242c.22.513.543.977.95 1.365a4.25 4.25 0 001.414.896c.527.203 1.091.3 1.657.284zM8.305 10.333H25.43a4.308 4.308 0 011.658.285 4.25 4.25 0 011.414.895c.406.389.729.853.95 1.366a4.107 4.107 0 010 3.242c-.221.513-.544.977-.95 1.366a4.25 4.25 0 01-1.414.895c-.528.203-1.091.3-1.658.285h-6.784a4.275 4.275 0 00-2.925 1.259 4.131 4.131 0 00-1.201 2.907c0 1.086.43 2.13 1.201 2.908.771.778 1.82 1.23 2.925 1.259h52.821a4.306 4.306 0 001.658-.284 4.248 4.248 0 001.414-.896c.406-.388.729-.853.95-1.366a4.107 4.107 0 000-3.242 4.167 4.167 0 00-.95-1.366 4.248 4.248 0 00-1.414-.895 4.306 4.306 0 00-1.658-.284h-8.999a4.275 4.275 0 01-2.924-1.26 4.131 4.131 0 01-1.202-2.907c0-1.086.431-2.13 1.202-2.907a4.275 4.275 0 012.924-1.26h25.89a4.275 4.275 0 002.924-1.259 4.131 4.131 0 001.202-2.907c0-1.086-.431-2.13-1.202-2.908A4.275 4.275 0 0088.358 2H8.31a4.275 4.275 0 00-2.924 1.26 4.131 4.131 0 00-1.202 2.907c0 1.086.432 2.129 1.202 2.907s1.82 1.23 2.924 1.26h-.006z"
                    opacity="0.5"
                ></path>
            </g>
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M71 13h-1.65M53.14 27H34.342m-9.193 0h1.468m35.25-14H16"
            ></path>
            <g clipPath="url(#clip2_1_110)">
                <path
                    fill="#C5EFF9"
                    d="M102.294 47.746c.098.047.18.123.234.218 1.928 3.877 2.276 8.487-.871 12.188-2.204 2.59-5.092 4.102-8.31 4.914-3.134.79-6.336 1.17-9.586.778-3.593-.432-5.272-1.674-6-5.233-.597-2.896-.792-5.885-1.019-8.843-.199-2.604.274-2.038-2.119-2.446-.517-.088-1.04-.166-1.545-.299-1.454-.385-2.6-1.14-3.062-2.676l-.013-.653c.932-3.583 2.885-6.647 5.012-9.584.894-1.233 1.043-2.423.985-3.881-.1-2.549-.142-5.142.213-7.657.865-6.161 4.521-10.488 9.687-13.572"
                ></path>
            </g>
            <path
                fill="#131E29"
                d="M66 41.946c1.015-3.884 3.14-7.204 5.456-10.387.973-1.336 1.135-2.626 1.072-4.206-.108-2.762-.154-5.572.232-8.298.946-6.676 4.925-11.365 10.55-14.707C89.45.701 96.203-.425 103.243.137c2.429.193 4.891.533 7.231 1.188 9.312 2.601 14.409 8.97 15.843 18.388 1.231 8.087-.494 15.667-4.165 22.875-2.018 3.965-3.368 8.13-4.013 12.542-.336 2.303.137 4.47.722 6.641.372 1.38.832 2.736 1.202 4.117.952 3.557-.941 6.695-4.543 7.32a1371.57 1371.57 0 01-22.352 3.714c-3.901.611-6.827-2.51-6.126-6.334.155-.847.559-1.393 1.47-1.253.855.131 1.06.771 1.008 1.556-.182 2.75 1.147 3.94 3.858 3.49 7.127-1.193 14.254-2.384 21.382-3.573 2.471-.415 3.523-2.106 2.828-4.535-.27-.942-.521-1.893-.855-2.813-1.622-4.484-1.526-9.002-.073-13.467.957-2.942 2.015-5.879 3.353-8.662 2.812-5.85 4.399-11.878 4.12-18.44-.455-10.749-6.3-17.845-16.878-19.77-7.625-1.386-15.098-.788-22.02 3.008-5.158 2.829-8.982 6.91-9.923 12.94-.436 2.794-.368 5.695-.255 8.536.073 1.829-.14 3.34-1.217 4.926-1.806 2.656-3.366 5.492-4.85 8.346-.817 1.574-.543 1.94 1.223 2.3 1.235.25 2.505.327 3.76.477 1.48.176 1.75.545 1.84 2.043.215 3.58.292 7.185.801 10.725.56 3.888 1.33 4.595 5.213 4.894 4.963.38 9.806-.282 14.119-3.046 4.164-2.67 5.456-6.414 3.934-11.117-.188-.582-.58-1.144-.595-1.723-.002-.135.013-.27.045-.402.192-.81 1.095-1.253 1.829-.857a.58.58 0 01.255.236c2.098 4.201 2.477 9.197-.949 13.208-2.4 2.808-5.544 4.446-9.046 5.326-3.413.857-6.9 1.267-10.438.843-3.912-.468-5.74-1.814-6.534-5.672-.646-3.138-.861-6.377-1.108-9.583-.216-2.822.299-2.208-2.307-2.65-.563-.096-1.132-.18-1.683-.324-1.582-.417-2.83-1.236-3.333-2.9L66 41.946z"
            ></path>
            <path
                fill="#131E29"
                d="M94.188 35.656a1.621 1.621 0 01-2.138-.1c-1.604-1.57-3.18-3.17-4.77-4.755-.61-.607-1.003-1.286-.271-2.01.721-.71 1.425-.337 2.031.26 1.076 1.061 2.189 2.09 3.191 3.215.73.82 1.202.747 1.954-.011 2.1-2.117 4.204-4.23 6.313-6.34 2.108-2.11 4.217-4.217 6.327-6.324 2.078-2.073 4.177-4.12 6.22-6.229.332-.343.642-.558 1.13-.589.347-.021.841-.042 1.104.235a.699.699 0 01.174.423c.054.614-.152 1.305-.635 1.705-.216.176-.388.382-.584.577l-1.586 1.583-3.173 3.166-6.346 6.33c-2.116 2.11-4.232 4.22-6.35 6.327l-2.191 2.182c-.126.126-.26.244-.4.355z"
            ></path>
            <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M63 74h8M67 78v-8M137.171 51.127a5.154 5.154 0 01-.857 2.848 5.096 5.096 0 01-2.282 1.888 5.05 5.05 0 01-5.542-1.111 5.157 5.157 0 01-1.103-5.587 5.12 5.12 0 011.873-2.301 5.058 5.058 0 016.422.638 5.15 5.15 0 011.489 3.624v0z"
            ></path>
            <defs>
                <clipPath id="clip0_1_110">
                    <path
                        fill="#fff"
                        d="M0 0H104V25H0z"
                        transform="translate(77 63)"
                    ></path>
                </clipPath>
                <clipPath id="clip1_1_110">
                    <path
                        fill="#fff"
                        d="M0 0H107V25H0z"
                        transform="rotate(-180 53.5 13.5)"
                    ></path>
                </clipPath>
                <clipPath id="clip2_1_110">
                    <path
                        fill="#fff"
                        d="M0 0H34V55H0z"
                        transform="translate(70 11)"
                    ></path>
                </clipPath>
            </defs>
        </svg>
    );
}

export default PeaceOfMindIcon;
